import React, {useContext, useRef, useState} from "react";
import {useQuery} from "@apollo/client";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Box, CircularProgress, Stack} from "@mui/material";

import AuthContext from "../containers/Auth/AuthProvider";
import {
  Chart,
  FormularioProyecto,
  Gantt,
  MenuCarteraProyectos,
  ProjectsList,
} from "../containers/WebApp/components";
// import useProjectsInfo from "../containers/WebApp/hooks/useProjectsInfo";
import projectsInfo from "../containers/WebApp/components/Charts/logic";
import WebAppLayout from "../containers/WebApp/WebAppLayout";
import {projectsVar} from "../gatsby-plugin-apollo/cache";
import {GET_PROJECTS} from "../gatsby-plugin-apollo/queries";
import {isBrowser} from "../utils";

const Proyectos = ({projectIndex, wbsIndex, name}) => {
  // CONSULTAS DE DATOS:
  // Datos de autenticación:
  const {isLoading} = useContext(AuthContext);
  // Datos de proyectos:
  const projects = projectsVar();
  const {data, loading, error} = useQuery(GET_PROJECTS, {
    variables: {
      getProyectosId: "63038693768ee8e7b37a1b48",
    },
  });

  // Datos de tiempos:
  const {intervalDatesInfo, timeSpan} = projectsInfo(projects);

  // Dato de la descripción:
  const description = projectIndex ? projects[projectIndex][wbsIndex].description : "";

  // MANEJADORES DEL FORMULARIO PROGRESIVO:
  const [displayProgressive, setDisplayProgressive] = useState(false);
  const handleDisplayProgressive = () => {
    setDisplayProgressive(!displayProgressive);
  };

  // MANEJADORES DE LA SELECCIÓN DE PROYECTO Y FECHA:
  const [selectedItem, setSelectedItem] = useState(0);
  const handleItemSelect = (itemIndex) => {
    setSelectedItem(itemIndex);
  };
  const [hoveredItem, setItemHover] = useState(0);
  const handleItemHover = (itemIndex) => {
    setItemHover(itemIndex);
  };
  const [hoveredTime, setTimeHover] = useState("");
  const handleTimeHover = (timeIndex) => {
    setTimeHover(timeIndex);
  };

  // MANEJADORES DE LA SINCRONIZACIÓN DE SCROLLS:
  const projectsRef = useRef();
  const ganttRef = useRef();
  const ganttSpanRef = useRef();
  const chartRef = useRef();
  const handleProjectScroll = (scroll) => {
    ganttRef.current.scrollTop = scroll.target.scrollTop;
  };
  const handleGanttScroll = (scroll) => {
    projectsRef.current.scrollTop = scroll.target.scrollTop;
  };
  const handleSpanScroll = (scroll) => {
    chartRef.current.scrollLeft = scroll.target.scrollLeft;
  };
  const handleChartScroll = (scroll) => {
    ganttSpanRef.current.scrollLeft = scroll.target.scrollLeft;
  };
  // GENERACIÓN DE LISTA DE PROYECTOS:
  const generateList = (inputArray, projectIndex, wbsIndex) => {
    let outputArray = [];
    let index;

    if (projectIndex && wbsIndex) {
      for (let i = 0; i < inputArray[projectIndex][wbsIndex].descendants.length; i++) {
        index = inputArray[projectIndex][wbsIndex].descendants[i];

        outputArray.push({
          ...inputArray[projectIndex][inputArray[projectIndex][wbsIndex].descendants[i]],
          index,
        });
      }
    } else {
      for (let j = 0; j < inputArray.length; j++) {
        index = j;
        outputArray.push({...inputArray[j][0], index});
      }
    }

    return outputArray;
  };

  return (
    <WebAppLayout getPorjectError={error} getPorjectLoading={loading} getProjectData={projects}>
      <Box>
        {isLoading ? (
          <Stack alignItems="center" height={"100%"} justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Stack display={displayProgressive ? "flex" : "none"} height={"100%"}>
              <FormularioProyecto close={handleDisplayProgressive} />
            </Stack>
            <Stack
              backgroundColor={"secondary.dark"}
              display={displayProgressive ? "none" : "flex"}
              flexGrow={1}
              height={"100%"}
              justifyContent={"space-evenly"}
              p={2}
              spacing={2}
            >
              <Stack
                direction={"row"}
                flexGrow={1}
                height={"45%"}
                justifyContent={"space-between"}
                spacing={2}
              >
                <ProjectsList
                  data={generateList(projects, projectIndex, wbsIndex)}
                  hover={{hoveredItem, handleItemHover}}
                  indexes={{projectIndex, wbsIndex}}
                  scroll={{projectsRef, handleProjectScroll}}
                  select={{selectedItem, handleItemSelect}}
                  status={{loading, error}}
                />
                <Gantt
                  data={generateList(projects, projectIndex, wbsIndex)}
                  hover={{
                    hoveredItem,
                    handleItemHover,
                    hoveredTime,
                    handleTimeHover,
                  }}
                  scroll={{
                    ganttRef,
                    ganttSpanRef,
                    handleGanttScroll,
                    handleSpanScroll,
                  }}
                  select={{selectedItem, handleItemSelect}}
                  status={(error, loading)}
                  time={{intervalDatesInfo, timeSpan}}
                />
              </Stack>
              <Stack
                direction={"row"}
                flexGrow={1}
                height={"45%"}
                justifyContent={"space-between"}
                spacing={2}
              >
                <MenuCarteraProyectos
                  data={{name, description, projectIndex}}
                  newProject={handleDisplayProgressive}
                />
                <Chart
                  data={generateList(projects, projectIndex, wbsIndex)}
                  hover={{
                    hoveredTime,
                    handleTimeHover,
                  }}
                  scroll={{
                    chartRef,
                    handleChartScroll,
                  }}
                  select={{selectedItem, handleItemSelect}}
                  status={{error, loading}}
                  time={{intervalDatesInfo, timeSpan}}
                />
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </WebAppLayout>
  );
};

// PROTECCIÓN DE RUTA:
const destination = isBrowser() && window.location.pathname;

export default withAuthenticationRequired(Proyectos, {
  returnTo: destination,
});
